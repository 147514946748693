import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Container as GridContainer } from '../../styles/grid'
import { mobile, rem } from '../../styles/functions'
import { colors, fontWeights } from '../../styles/variables'

export const Wrapper = styled.section`
  width: 100%;
  max-width: 1300px;
  padding: 0 20px;
  margin: 0 auto;
  height: 200px;
  box-sizing: border-box;

  .home-header-logo {
    width: 100%;

    a {
      margin: 20px auto 0;
      text-align: center;
      display: block;

      img {
        height: 45px;
      }
    }
  }

  ${mobile(css`
    height: auto;
    padding: 0 12px;

    .home-header-logo {
      height: 40px;

      a {
        img {
          height: 28px;
        }
      }
    }
  `)}
`

export const SearchSection = styled.div`
  display: block;
  margin: 0 auto;
  max-width: 860px;
  padding: 50px 0;

  h2 {
    color: #fff;
  }

  .image-no {
    color: #67607d;
    font-size: 1.5em;
  }

  form {
    align-items: center;
    cursor: text;
    display: flex;
    flex: 1 1 100%;
    font-size: 14px;
    padding: 10px;
    border: 1px solid transparent;
    box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16);
    border-radius: 24px;
    background-color: #fff;

    .fa {
      font-weight: 900;
      font-size: 21px;
    }

    .fa-search {
      display: flex;
      color: #ff1b9b;
      margin: 0px 8px;
    }

    input {
      text-indent: 16px;
      height: 30px;
      border: 0px;
      width: 100%;
      font-size: 1rem;
      font-weight: 400;
    }

    input:focus {
      outline: none;
    }

    button {
      border: none;
      background: transparent;
      cursor: pointer;
    }
  }

  ${mobile(css`
    padding: 12px 0;
  `)}
`

export const HeaderSearch = styled.section`
  height: 65px;
  position: relative;
  z-index: 3;
  box-shadow: 0 0 4px 3px rgba(79,79,79,.14);
  width: 100%;

  .wrapper {
    width: 100%;
    max-width: 1300px;
    padding: 0 20px;
    margin: 0 auto;
  }

  .header {
    display: table;
    width: 100%;
  }

  .header-logo {
    width: 270px;

    a {
      margin: 0;
      display: block;

      img {
        width: 220px;
        margin-top: 20px;
      }
    }
  }

  .header>* {
    display: table-cell;
    vertical-align: top;
  }

  .header-form {
    width: auto;
    padding: 11px 0 0;
    
    .no-border {
      border: none;
    }

    form {
      transition-delay: 10ms;
      display: block;
      position: relative;
      border: 1px solid #909ce5;

      input {
        display: block;
        height: 40px;
        border: none;

        width: 100%;
        font-size: 13px;
        color: #333;
        background: #fff;
        padding: 10px 50px 10px 15px;
        -webkit-appearance: none;

        :focus {
          outline: none;
        }
      }

      button {
        display: block;
        height: 40px;
        border: none;
        background: none;

        position: absolute;
        right: 0;
        top: 0;
        width: 40px;
        cursor: pointer;
      }

      button:before {
        color: #666;
        font-family: icomoon;
        color: #fff;
        font-size: 25px;
        font-weight: 700;
      }
    }
  }

  ${mobile(css`
    height: 50px;

    .wrapper {
      padding: 0 12px;
      box-sizing: border-box;
    }

    .header-logo {
      padding-left: 0;
      padding-right: 10px;
      width: 50px;

      a {
        width: 35px;
        height: 50px;
        overflow: hidden;

        img {
          display: inline-block;
          margin: 10px;
        }
      }
    }

    .header-form {
      padding: 4px 0 0;
      width: auto;
    }
  `)}
}
`

export const SearchHeaderWrapper = styled.section`
  height: 65px;
  position: relative;
  z-index: 3;
  box-shadow: 0 0 4px 3px rgba(79, 79, 79, 0.14);

  .search-logo {
    margin: 20px;
  }

  img {
    max-width: 100%;
    height: 25px;
  }

  ${mobile(css`
    height: 60px;
  `)}
`

export const Container = styled(GridContainer)`
  max-width: 1160px;

  ${mobile(css`
    padding: 0 20px;
    height: 100%;
  `)}
`

export const Logo = styled.div`
  display: inline-block;

  ${mobile(css`
    max-width: 120px;
  `)}
`

export const SearchBox = styled(GridContainer)`
  max-width: 1160px;

  form {
    display: flex;
    flex-direction: row;
    position: relative;
    top: 13px;
    width: 100%;
    z-index: 1;
  }

  .open {
    animation: bounce-in 300ms ease-in;
  }

  .close {
    animation: bounce-out 300ms ease-in;
  }

  @keyframes bounce-in {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  @keyframes bounce-out {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(0.5);
    }
  }

  input {
    background: #f7ee6f;
    color: #7d7d7d;
    text-transform: capitalize;
    text-align: center;
    display: inline-block;
    outline: none;
    padding: 12px 30px 12px 16px;
    border-radius: 20px;
    border: none;
    width: 100%;
    font-size: 30px;
    box-shadow: 0px 2px 3px 0px rgb(0 0 0 / 22%);
  }

  ${mobile(css`
    padding: 0 20px;
    height: 100%;
  `)}
`

export const MenuWrapper = styled.ul`
  display: inline-block;
  list-style-type: none;
  margin: 26px 30px 0 120px;
  padding: 0;
  flex-grow: 1;
  text-align: right;

  li {
    display: inline-block;
    margin: 0;
    margin-right: 45px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  a {
    color: ${colors.contrast};
    text-decoration: none;
    font-size: ${rem(17)};
    font-weight: ${fontWeights.medium};

    position: relative;
    padding: 0.3em 0;

    &.is-active {
      color: ${colors.contrast};
    }
  }

  a::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.2em;
    background-color: ${colors.contrast};
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
  }

  a:hover::after,
  a:focus::after {
    opacity: 1;
    transform: translate3d(0, 0.2em, 0);
  }

  ${mobile(css`
    margin: 0;
    text-align: right;
    flex-grow: 1;
    align-self: center;

    li {
      margin-right: 20px;
      margin-bottom: 4px;
    }
  `)}
`

export const SearchWrapper = styled.div`
  display: inline-block;
  padding-top: 12px;

  form {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 70px;
  }

  input {
    display: inline-block;
    outline: none;
    padding: 12px 30px 12px 16px;
    border-radius: 20px;
    border: 1px ${colors.lightGrey} solid;
    width: 320px;
  }

  button {
    background-color: transparent;
    border: none;
    color: ${colors.contrast};
    transform: translate3d(0, -50%, 0);
    font-size: 18px;
    cursor: pointer;
  }

  .search-close-icon {
    position: relative;
    top: 24px;
  }

  ${mobile(css`
    input {
      width: auto;
    }
  `)}
`
